import { Fragment, useContext, useEffect, useState } from 'react';
import { CategoriesContext } from '../../contexts/categories.context';
import { useNavigate } from 'react-router-dom';
import CategoryPreview from '../category-preview/category-preview.component';
import HerbsAndTeaComponent from './herbs-and-tea.component';
import './directory-item.styles.scss';

const DirectoryItem = ({ category, categoryProducts }) => {
  const { imageUrl, title, route } = category;

  const navigate = useNavigate();
  const onNavigateHandler = () => {
    navigate(route);
    window.scrollTo(0, 0);
  };
  const isSpecialTitle =
    title === 'Work and Hair' ||
    title === 'Home Girl' ||
    title === 'Food and Hair';

  return (
    <Fragment>
      <div className="directory-item-container" onClick={onNavigateHandler}>
        {title === 'Coils and Curls' && (
          <p>If my hair could talk it would...</p>
        )}
        <div
          className={`background-image ${isSpecialTitle ? 'wholeimg' : ''}`}
          style={{ backgroundImage: `url(${imageUrl})` }}
        />
        {/* {title === 'Work and Hair' && (
          <p>
            He didn't touch my hair, not right there and then but he was looking
            at it...
          </p>
        )} */}
        <div className="body">
          {title === 'Food and Hair' ? (
            <HerbsAndTeaComponent />
          ) : (
            <>
              <h2>{title}</h2>
              <p>View</p>
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default DirectoryItem;
