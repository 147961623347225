import React, { useEffect, useRef, Animated } from 'react';
import Lottie from "lottie-react";
const ResetPassword = ({ redirectLink, resetPasswordloopImg }) => {
  const animation = useRef(null)
  return (
    <div style={{
      flex: 1,
      color: '#303032', textAlign: 'center',
      backgroundColor: 'rgb(194, 216, 76)',
      padding: 120
    }}>
      <div
        style={{
          fontFamily: 'Avenir, Roboto, Arial, Sans-Serif',
        }}
      >



        <h1 className="title" style={{ margin: 'auto', textAlign: 'center', fontFamily: 'beezle' }}>
          PASSWORD RESET FOR NEW DEW
        </h1>
      </div>


      <div
        className="msgBody"
        style={{
          fontFamily: 'Avenir, Roboto, Arial, Sans-Serif',
          backgroundColor: '#efede7',
          alignContent: 'normal',
          height: '100%',
          margin: 'auto',
          width: '70%',
          padding: '50px',
          borderRadius: '9px',
        }}
      >
        <div style={{ flexDirection: "row" }}>
          <Lottie
            animationData={require("../../assets/eve1.json")}
            style={{
              width: 300,
              height: 300,
              marginBottom: 30,
              justifyContent: "center",
              alignContent: "center",
              alignSelf: "center",
              alignItems: "center"
            }}
            autoPlay
          />
          <h1 className="titleHead" style={{ textAlign: 'center' }}>
            Reset Password
          </h1>
        </div>

        <div
          style={{
            fontFamily: 'Avenir, Roboto, Arial, Sans-Serif',
            backgroundColor: '#ddd',
            height: 250,
            padding: 60
          }}
        >

          <p>
            Please follow this link to{' '}
            <a href={redirectLink} style={{ cursor: 'pointer' }}>
              <button
                className="btn"
                style={{
                  border: '2px solid black',
                  backgroundColor: 'white',
                  color: 'black',
                  padding: '10px 28px',
                  fontSize: '16px',
                  cursor: 'pointer',
                }}
              >
                reset
              </button>
            </a>{' '}
            your password.
          </p>
          <small>This link expires in 60 minutes.</small>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

